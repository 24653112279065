import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import GlobalLayout from 'components/GlobalLayout'
import { getUrlVars } from 'utils/urlHelpers'
import RegisterDispensaryForm from 'components/RegisterDispensaryForm'
import { StaticQuery, graphql } from 'gatsby'
import { authConditionIsUser } from 'utils/securityHelpers'
import useAuthorization from 'hooks/useAuthorization'

function RegisterDispensary({ location }) {

	const [dispensaryId, setDispensaryId] = useState(null)
	const { authUser } = useAuthorization(authConditionIsUser)

	useEffect(() => {
		setDispensaryId(getUrlVars(document.location, 'id'))
	}, [])
	return (
		<GlobalLayout location={location}>
			<>
				{!dispensaryId ? (
					<div> This is not the page you are looking for</div>
				) : (
						<StaticQuery
							query={graphql`
											query claimDispensaryQuery {
												allDispensariesJson {
													edges {
														node {
															dispensary {
																about
																ADA
																address1
																address2
																admins {
																	userId
																}
																atm
																averageRating
																AzLicensed
																city
																country
																creditCards
																delivery
																geoLocation {
																	_latitude
																	_longitude
																}
																hasEdibles
																hours
																id
																labTested
																licensed
																medical
																name
																openVape
																paysLeafly
																paysPremiumFee
																phone
																preOrderEnabled
																retail
																reviews {
																	id
																	dateCreated
																	defaultAvatarThemeIndex
																	nodeName
																	profileImageURL
																	prefersUsername
																	username
																	firstName
																	lastName
																	rating
																	reviewText
																	reviewTitle
																	reviewType
																	reviewerName
																	slug
																	state
																	userID
																}
																slug
																state
																storefront
																tagLine
																totalReviews
																UFCW
																veteranDiscount
																zip
															}
														}
													}
												}
											}
										`}
						>
							{data => {
								const dispenaryData = data.allDispensariesJson.edges.filter(
									edge => edge.node.dispensary.id === dispensaryId
								)[0]
								const {
									node: { dispensary },
								} = dispenaryData
								return (
									<RegisterDispensaryForm
										pathname={location.pathname}
										dispensary={dispensary}
										authUser={authUser}
									/>
								)
							}}
						</StaticQuery>
					)}
			</>
		</GlobalLayout>
	)
}
RegisterDispensary.propTypes = {
	location: PropTypes.object,
}
export default RegisterDispensary
