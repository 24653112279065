import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/emotion'
import { Field } from 'react-final-form'
import styled from '@emotion/styled'
import { PhoneField, InputFieldFF, TextAreaField, StateSelectField } from 'components/FormFields'

const FieldLabel = styled.label`
	text-transform: uppercase;
	font-weight: bold;
`

export default class DispensaryFormFields extends React.Component {
	static propTypes = {
		dispensary: PropTypes.object,
		displayFields: PropTypes.array,
	}
	static defaultProps = {
		dispensary: null,
		displayFields: [
			{ name: 'name' },
			{
				name: 'phone',
				component: PhoneField,
			},
			{ name: 'about', component: TextAreaField },
			{ name: 'tagline' },
			{ name: 'address1' },
			{ name: 'address2' },
			{ name: 'city' },
			{ name: 'state', component: StateSelectField },
			{ name: 'zip' },
		],
	}
	render() {
		const { dispensary, displayFields } = this.props
		return (
			<>
				{dispensary && (
					<Flex flexDirection="column">
						{displayFields.map(({ name, component }) => (
							<Box key={`${dispensary.id}_${name}`} py={2}>
								<FieldLabel>{name}</FieldLabel>
								<Field name={name} component={component || InputFieldFF} />
							</Box>
						))}
					</Flex>
				)}
			</>
		)
	}
}
