import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { StyledButton, ErrorMessage } from 'components/FormStyles'
import Loader from 'components/Loader'
import { COLORS } from 'utils/styleHelpers'
import { Flex, Box } from '@rebass/emotion'
import { css } from '@emotion/core'
import { navigate } from 'gatsby'
//TODO: refactor to hooks
export default class Wizard extends React.Component {
	//TODO: make proptypes for specific
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node
		]),
		confirmationPage: PropTypes.string,
		initialValues: PropTypes.object,
		onSubmit: PropTypes.func.isRequired,
		values: PropTypes.any
	};
	static defaultProps = {
		confirmationPage: '',
		onSubmit: () => true,
	}
	static Page = ({ children }) => children
	state = {
		page: 0,
		value: this.props.initialValues,
		responseText: '',
		responseStatus: null,
		isLoading: false,
	}
	componentDidMount() {
		const { values } = this.props
		this.setState({ values })
	}

	next = values => {
		const { children } = this.props
		this.setState(state => ({
			page: Math.min(state.page + 1, children.length - 1),
			values,
			responseMessage: '',
		}))
		typeof window !== 'undefined' && window.scrollTo({ top: 0, behavior: 'smooth' })
	}
	previous = () => {
		this.setState(state => ({
			page: Math.max(state.page - 1, 0),
			responseMessage: '',
		}))
		typeof window !== 'undefined' && window.scrollTo({ top: 0, behavior: 'smooth' })
	}
	handleSubmit = async values => {
		const { children, onSubmit, confirmationPage } = this.props
		const { page } = this.state
		const isLastPage = page === React.Children.count(children) - 1
		if (isLastPage) {
			this.setState({ isLoading: true, responseMessage: '' })
			const { responseMessage, responseStatus } = await onSubmit(values)
			this.setState({ isLoading: false })

			this.setState({ responseMessage, responseStatus })
			if (responseStatus === 200) {
				navigate(confirmationPage)
			}
		} else {
			this.next(values)
		}
	}
	validate = values => {
		const { page } = this.state
		const activePage = React.Children.toArray(this.props.children)[page]
		return activePage.props.validate ? activePage.props.validate(values) : {}
	}
	render() {
		const { children, values } = this.props
		const { page, responseMessage, responseStatus, isLoading } = this.state
		const activePage = React.Children.toArray(children)[page]
		const isLastPage = page === React.Children.count(children) - 1
		return (
			<>
				{isLoading ? (
					<Loader />
				) : (
						<Form initialValues={values} validate={this.validate} onSubmit={this.handleSubmit}>
							{({ handleSubmit, submitting, values }) => {
								return (
									<form onSubmit={handleSubmit}>
										<Flex flexDirection="column">
											<Box>{activePage}</Box>
											<Flex flexDirection="row">
												{page > 0 && (
													<Box
														py={2}
														mx={1}
														css={css`
														flex-grow: 1;
													`}
													>
														<StyledButton
															bg={COLORS.GREEN}
															type="button"
															values={values}
															onClick={this.previous}
														>
															« Previous
													</StyledButton>
													</Box>
												)}
												{!isLastPage && (
													<Box
														py={2}
														mx={1}
														css={css`
														flex-grow: 1;
													`}
													>
														<StyledButton bg={COLORS.GREEN} type="submit">
															Next »
													</StyledButton>
													</Box>
												)}
											</Flex>
											{isLastPage && (
												<Box>
													<StyledButton bg={COLORS.BLACK} type="submit" disabled={submitting}>
														Submit
												</StyledButton>
												</Box>
											)}
											{responseMessage !== '' && responseStatus !== 200 && (
												<ErrorMessage>{responseMessage}</ErrorMessage>
											)}
										</Flex>
									</form>
								)
							}}
						</Form>
					)}
			</>
		)
	}
}
