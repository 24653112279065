import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/emotion'
import * as firebase from 'firebase/app'
import axios from 'axios'
import { Field } from 'react-final-form'
import { css } from '@emotion/core'
import Wizard from './Wizard'
import { PhoneField } from 'components/FormFields'
import { ErrorMessage } from 'components/FormStyles'
import DispensaryFormFields from 'components/DispensaryFormFields'
import { SPACERS } from 'utils/styleHelpers'
import { PATH_HISTORY_STORAGE } from 'utils/constants'
import { apiDispensariesURL } from 'utils/apiHelpers'

const required = value => (value ? undefined : 'Required')
const validPhone = value => {
	if (value) {
		const normalized = value.replace(/\D/g, '')
		return normalized.length === 10 ? undefined : 'Invalid Phone Number'
	}
	return undefined
}
//TODO: refactor to hooks
class RegisterDispensaryWizard extends React.Component {
	//TODO: make proptypes for specific
	static propTypes = {
		dispensary: PropTypes.object,
		pathname: PropTypes.string,
		authUser: PropTypes.object,
	}
	onSubmit = async values => {
		const { dispensary, pathname } = this.props
		const { adminContactPhone } = values
		try {
			window.localStorage.setItem(PATH_HISTORY_STORAGE, `/${pathname}`)
			let currentUser = await firebase.auth().currentUser
			const token = await currentUser.getIdToken(true)
			const config = {
				// eslint-disable-next-line quote-props
				headers: { Authorization: `Bearer ${token}` },
			}
			const response = await axios.post(
				`${apiDispensariesURL}/claim`,
				{
					dispensaryData: { ...values, adminContactPhone },
					dispensaryId: dispensary.id,
					userId: currentUser.uid,
				},
				config
			)
			return { responseMessage: response.data.message, responseStatus: response.status }
		} catch (e) {
			console.warn(e.response.data.message)
			return { responseMessage: e.response.data.message, responseStatus: e.response.status }
		}
	}
	render() {
		const { dispensary } = this.props
		return (
			<>
				<Wizard onSubmit={this.onSubmit} values={dispensary} confirmationPage={`/registerDispensaryConfirm`}>
					<Wizard.Page
						validate={values => {
							const errors = {}
							if (!values.adminContactPhone) {
								errors.adminContactPhone = 'Required'
							}
							return errors
						}}
					>
						<Flex flexDirection="column">
							<Box py={2}>
								<p
									css={css`
										padding: ${SPACERS.M};
									`}
								>
									Thank you for your interest in Weedrater.com. To get started, we kindly ask you for
									a valid phone number. We may contact you by phone to verify your ownership of this
									dispensary.{' '}
								</p>
							</Box>
							<Box py={2}>
								<span>Your Contact Phone Number</span>
							</Box>
							<Box py={2}>
								<Field name="adminContactPhone" validate={required && validPhone}>
									{({ input, meta }) => {
										return (
											<>
												<PhoneField {...input} />
												{meta.error && meta.touched && (
													<ErrorMessage>{meta.error}</ErrorMessage>
												)}
											</>
										)
									}}
								</Field>
								{/* <Error name="phone" /> */}
							</Box>
						</Flex>
					</Wizard.Page>
					<Wizard.Page>
						<Box p={2}>
							<span>
								Take a look at what we have on file for your dispensary and make sure we have everything
								correct.
							</span>
						</Box>
						<DispensaryFormFields dispensary={dispensary} />
					</Wizard.Page>
				</Wizard>
			</>
		)
	}
}

export default RegisterDispensaryWizard
